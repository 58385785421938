// Entry point for the build script in your package.json
import '@hotwired/turbo-rails'
import './controllers'

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap
import * as Popper from "@popperjs/core"

import jquery from 'jquery'
window.jQuery = jquery
window.$ = jquery

require('jquery-validation')

import { tns } from "tiny-slider"
window.tns = tns

// import './v2/custom';
// import * as geocomplete from 'geocomplete'
document.addEventListener("turbo:load", () => {
  if($('body').width() > 767){
    var selectorList = ['.extra-des-plan', '.card-height-section', '.description-plan-height', '.description-plan-height-tv', '.detail-section-card-tv']
    $.each(selectorList, function(index, selector){
      semtrixHeight(selector)
    })
  }
})
window.semtrixHeight = function(selector) {
  var maxHeight = findMaxHeight(selector)
  $(selector).css('min-height', (maxHeight + 10) + 'px');
}

window.findMaxHeight = function(selector) {
  let max = 0
  $(selector).each(function(index, item){
    var itemHeight = item.offsetHeight
    if(max < itemHeight)
      max = item.offsetHeight
  });
  return max
}
